import { cn } from "@watt/common";
import type React from "react";

export type TextareaProps = React.ComponentProps<"textarea">;

const Textarea: React.FC<TextareaProps> = ({ ref, className, ...props }) => (
  <textarea
    className={cn(
      "flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:font-medium file:text-sm placeholder:text-muted-foreground hover:bg-accent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:bg-muted disabled:opacity-50",
      className
    )}
    ref={ref}
    {...props}
  />
);

Textarea.displayName = "Textarea";

export { Textarea };
